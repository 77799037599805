import mitt from 'mitt';

import { FormattingManager } from '../services/formattingManager.ts';
import { formatters } from '../utils/formatters.ts';
import { sortFns } from './sorting';
import { SortingManager } from './sortingManager';
import { ICoreExtension, SortFunction } from './types';

/**
 * Registers a Piivo extension point
 *
 * @param path - the path to register at
 * @param extensionPoint - the extension point
 */
export function registerExtensionPoint(path: string, extensionPoint: unknown): void {
  if (window && !window.Piivo) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.Piivo = {} as { [extension: string]: any };
  }
  if (window) {
    window.Piivo[path] = extensionPoint;
  }
}

/**
 * Gets a Piivo extension point
 *
 * @param path - the path of the extension point
 * @returns the extension point
 */
export function getExtensionPoint<T>(path: string): T {
  if (window && !window.Piivo) {
    throw new Error('Piivo extension point is not initialized');
  }

  if (window) {
    const value = window.Piivo[path] as T;
    if (value != null) {
      return value;
    }
  }

  throw new Error(`Did not find extension point ${path}`);
}

const sortingManager = new SortingManager();
sortFns.forEach((sortFn) => {
  sortingManager.registerSortFn(sortFn.name, sortFn.func as SortFunction);
});

const formattingManager = new FormattingManager();
for (const formatter of formatters) {
  formattingManager.registerFormatter(formatter.name, formatter.func);
}

export const coreExtension: ICoreExtension = {
  eventBus: mitt(),
  sortingManager: sortingManager,
  formattingManager: formattingManager,
};

registerExtensionPoint('core', coreExtension);

export default {
  registerExtensionPoint,
  getExtensionPoint,
};
