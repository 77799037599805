import { coreExtension } from '../extensionPoints/index.ts';

export class FormattingManager implements IFormattingManager {
  formatters = new Map<string, FormattingFunction>();

  /**
   *
   * @param name - the name for which to register the formatter
   * @param formatterFunction - the formatter function
   */
  registerFormatter(name: string, formatterFunction: FormattingFunction): void {
    this.formatters.set(name, formatterFunction);
  }

  /**
   *
   * @param name - the name of the formatter to retrieve
   * @returns the formatter function
   */
  getFormatter(name: string): FormattingFunction {
    if (!this.formatters.has(name)) {
      throw Error(`No registered formatter found under "${name}" name`);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.formatters.get(name)!;
  }

  /**
   *
   * @returns the list of registered formatters
   */
  getRegisteredFormatterNames(): string[] {
    return [...this.formatters.keys()];
  }
}

/**
 *
 * @returns the current formatting manager in the extension point
 */
export function getFormattingManager(): IFormattingManager {
  return coreExtension.formattingManager;
}
