var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pui-attribute-table pui-attribute-table__root"},[(_vm.isImporting)?_c('pui-common-spinner',{attrs:{"message":_vm.$t('common.attribute_table.isImporting'),"position":"fixed"}}):_vm._e(),_c('pui-flex',{staticClass:"pui-attribute-table__top",attrs:{"direction":"column"}},[_c('pui-flex',{attrs:{"wrap":"wrap"}},[(_vm.attribute.options.showLabel)?_c('pui-flex',{staticClass:"table-label",attrs:{"alignItems":"center"}},[_vm._v(" "+_vm._s(_vm._f("piivoTranslateLabel")(_vm.attribute.label))+" ")]):_vm._e(),_c('pui-flex',{staticClass:"pui-attribute-table__config-right",attrs:{"alignItems":"center"}},[(!_vm.disabled && _vm.activateSearch && _vm.attribute.search)?_c('a',{staticClass:"action-btn search-add-btn",on:{"click":_vm.onSearchClicked}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_table.search_add'))+" ")]):_vm._e(),(!_vm.disabled && !!_vm.attribute.options.valueImportConfiguration)?[_c('a',{staticClass:"action-btn import-btn",on:{"click":_vm.onClickImport}},[_vm._v(_vm._s(_vm.$t('common.attribute_table.import')))]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"importFile",attrs:{"type":"file"},on:{"change":_vm.onImportFile}})]:_vm._e(),(!_vm.disabled)?_c('pui-menu-button',{scopedSlots:_vm._u([{key:"default",fn:function({ closePopper }){return [_c('pui-button',{attrs:{"block":"","flat":""},on:{"click":function($event){closePopper();
                _vm.requestClearTable();}}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_table.clear'))+" ")])]}}],null,false,3397670065)}):_vm._e()],2)],1),(_vm.displayError && _vm.activateValidationMessages)?_c('pui-flex',{staticClass:"pui-attribute-table__error-message"},[_c('i',{staticClass:"error-icon mdi mdi-alert"}),_c('span',{staticClass:"msg"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()],1),_c('div',{ref:"table",staticClass:"pui-attribute-table__table",style:({ '--drag-handle-width': _vm.DRAG_HANDLE_WIDTH })},[_c('div',{ref:"tableHeader",staticClass:"pui-attribute-table__row header",style:(`width: max(${_vm.tableOptions.rowWidth}, 100%)`)},[(_vm.tableOptions.showOrder)?_c('pui-table-header',{class:{
          'pui-attribute-table__cell header': true,
          'sticky-cell': _vm.tableOptions.stickyFirstColumn,
        },style:({
          '--sticky-z-index': `${_vm.tableOptions.stickyFirstColumn ? 115 : null}`,
          '--sticky-offset': '0px',
        }),attrs:{"sortable":false,"visible":true,"width":_vm.DRAG_HANDLE_WIDTH}}):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('pui-table-header',{key:columnIndex,class:{
          'pui-attribute-table__cell header': true,
          'sticky-cell':
            _vm.tableOptions.stickyFirstColumn && columnIndex === _vm.firstVisibleColumnIndex,
        },style:({
          '--sticky-z-index': `${_vm.tableOptions.stickyFirstColumn ? 115 : null}`,
          '--sticky-offset': _vm.tableOptions.showOrder ? _vm.DRAG_HANDLE_WIDTH : '0px',
        }),attrs:{"label":column.name,"value":column.value,"variant":column.class,"width":column.width,"minimizedWidth":column.minimizedWidth,"sortable":false,"alignment":column.alignment,"visible":column.visible,"headerTranslator":column.headerTranslator}})}),_c('div',{staticClass:"row-menu-button-spacer"})],2),_c('transition-group',{staticClass:"row-container",attrs:{"tag":"div","name":"row-transition-group"}},_vm._l((_vm.allValues),function(value,valueIndex){return _c('pui-table-row',{key:value._id,ref:`row_${valueIndex}`,refInFor:true,class:{
          'pui-attribute-table__row body': true,
          'row-draggable': _vm.dnd.row === valueIndex,
          draggable: _vm.dnd.row === valueIndex,
        },style:(`width: max(${_vm.tableOptions.rowWidth}, 100%)`),attrs:{"item":value,"enableValidation":false,"draggable":_vm.tableOptions.showOrder && _vm.dnd.row === valueIndex},on:{"dragenter":_vm.onDragEnter,"dragover":function($event){return _vm.onDragOver($event, valueIndex)},"drop":_vm.onDrop,"dragstart":function($event){return _vm.onDragStart($event, valueIndex)},"dragend":_vm.onDragEnd,"click":function($event){return _vm.onDraggableElementClick(valueIndex)}}},[(_vm.tableOptions.showOrder)?_c('pui-flex',{class:{
            'drag-handle-cell': true,
            clickable: valueIndex < _vm.internalValues.length,
            draggable: _vm.dnd.row === valueIndex,
            'sticky-cell': _vm.tableOptions.stickyFirstColumn,
            faded: valueIndex === _vm.extraLineIndex,
          },style:({
            '--sticky-z-index': `${
              _vm.tableOptions.stickyFirstColumn ? (valueIndex === _vm.focusedRowIndex ? 115 : 110) : null
            }`,
            '--sticky-offset': '0px',
          }),attrs:{"justifyContent":"center","alignItems":"center"},on:{"click":function($event){return _vm.onDraggableElementClick(valueIndex)}}},[_vm._v(" "+_vm._s(valueIndex + 1)+" ")]):_vm._e(),_vm._l((_vm.columns),function(column,columnIndex){return _c('pui-table-cell',{key:columnIndex,class:_vm.cellClass(columnIndex, valueIndex),style:({
            '--sticky-z-index': `${
              _vm.tableOptions.stickyFirstColumn ? (valueIndex === _vm.focusedRowIndex ? 115 : 110) : null
            }`,
            '--sticky-offset': _vm.tableOptions.showOrder ? _vm.DRAG_HANDLE_WIDTH : '0px',
          }),attrs:{"variant":column.class,"width":column.width,"minimizedWidth":column.minimizedWidth,"alignment":column.alignment,"visible":column.visible,"validator":column.validator},on:{"click":function($event){_vm.setCurrentCell(columnIndex, valueIndex, $event);
            _vm.onDraggableElementClick(valueIndex);}}},[(!column.attribute)?_c('span'):_c('pui-attribute-panel',{key:column.itemId,ref:_vm.getRowCellName(columnIndex, valueIndex),refInFor:true,attrs:{"attribute":column.attribute,"value":value[columnIndex],"loadOptionsFunction":_vm.loadOptionsFunction,"loadOptionsParameters":{ context: { lineIndex: valueIndex } },"displayLabel":false,"allowExtendedDisplayMode":_vm.allowExtendedDisplayMode,"externalLinkProps":{ retractedColCount: 2, extendedColCount: 4, hideTopMenu: true },"tabindex":_vm.tabindex,"disabled":_vm.disabled || !column.editable,"activateValidationMessages":_vm.activateValidationMessages && valueIndex !== _vm.extraLineIndex,"lazyOptions":_vm.lazyOptions,"importExternalFiles":_vm.importExternalFiles},on:{"valueInput":function($event){return _vm.valueInput(columnIndex, valueIndex, $event)},"valueChanged":function($event){return _vm.valueChanged(columnIndex, valueIndex, $event)},"enter":function($event){return _vm.onKeyEnter(columnIndex, valueIndex)},"searchRequested":function($event){return _vm.$emit('searchRequested', $event)},"blur":function($event){return _vm.onCellBlurred(columnIndex, valueIndex)},"focus":function($event){return _vm.onCellFocused(columnIndex, valueIndex)}}})],1)}),_c('div',{class:{
            'row-menu-col': true,
            sticky: _vm.tableOptions.stickyRowMenu,
            draggable: _vm.dnd.row === valueIndex,
          },style:({
            '--z-index': `${valueIndex === _vm.focusedRowIndex ? 110 : 105}`,
          }),on:{"click":function($event){return _vm.onDraggableElementClick(valueIndex)}}},[(!_vm.disabled && valueIndex !== _vm.extraLineIndex)?_c('pui-menu-button',{attrs:{"popperListeners":{
              open: () => {
                _vm.focusedRowIndex = valueIndex;
              },
            },"stopPropagation":""},scopedSlots:_vm._u([{key:"default",fn:function({ closePopper }){return [_c('pui-flex',{attrs:{"direction":"column","flex":"1"}},[_c('pui-button',{attrs:{"block":"","flat":""},on:{"click":function($event){$event.stopPropagation();closePopper();
                    _vm.removeLine(valueIndex);}}},[_vm._v(_vm._s(_vm.$t('common.attribute_table.remove_line')))]),_c('pui-button',{attrs:{"block":"","flat":"","disabled":_vm.reachedMaxLines},on:{"click":function($event){$event.stopPropagation();closePopper();
                    _vm.duplicateLine(valueIndex);}}},[_vm._v(_vm._s(_vm.$t('common.attribute_table.duplicate_line')))])],1)]}}],null,true)}):_vm._e()],1)],2)}),1),_c('pui-attribute-table-indicators',{attrs:{"dnd":_vm.dnd}})],1),_c('pui-dialog',{ref:"clearDialog",staticClass:"clear-table-dialog clear-table-dialog__root",attrs:{"title":_vm.$t('common.attribute_table.clear_table_confirmation.title', {
        attribute: _vm.piivoTranslate(_vm.attribute),
      }),"showCloseButton":false,"transition":"slide-up"},on:{"outsideClick":_vm.closeClearTableDialog},scopedSlots:_vm._u([{key:"dialog-action",fn:function(){return [_c('div',{staticClass:"dialog-action"},[_c('pui-button',{staticClass:"clear-table-dialog__btn-cancel",attrs:{"variant":"secondary","outline":""},on:{"click":_vm.closeClearTableDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_table.clear_table_confirmation.cancel'))+" ")]),_c('pui-button',{staticClass:"clear-table-dialog__btn-create",attrs:{"variant":"primary"},on:{"click":_vm.confirmClearTable}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_table.clear_table_confirmation.clear'))+" ")])],1)]},proxy:true},{key:"default",fn:function(){return [_c('i18n',{staticClass:"message",attrs:{"path":"common.attribute_table.clear_table_confirmation.message","tag":"div"},scopedSlots:_vm._u([{key:"message_bold",fn:function(){return [_c('i18n',{staticClass:"message-bold",attrs:{"path":"common.attribute_table.clear_table_confirmation.message_bold"}})]},proxy:true},{key:"attribute",fn:function(){return [_vm._v(" "+_vm._s(_vm.piivoTranslate(_vm.attribute))+" ")]},proxy:true}])})]},proxy:true}])}),(_vm.reachedMaxLines)?_c('pui-dialog',{ref:"maxLinesReachedDialog",staticClass:"pui-attribute-table__max-lines-reached-dialog",attrs:{"showCloseButton":false,"transition":"slide-up","width":"40%"}},[_c('div',{staticClass:"dialog-action",attrs:{"slot":"dialog-action"},slot:"dialog-action"},[_c('pui-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.closeMaxLinesReachedDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('common.attribute_table.max_lines_reached_dialog.buttons.confirm'))+" ")])],1),_c('pui-error',{staticClass:"max-lines-reached-dialog-message",attrs:{"picto":"mdi-alert"}},[_c('i18n',{attrs:{"path":"common.attribute_table.max_lines_reached_dialog.message"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }